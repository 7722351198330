import PortableTextPlus from "@/components/portable-text-plus";
import CallToActionButton from "@/components/call-to-action-button";
import SegmentContainer from "./segment-container";
import classNames from "classnames";

export default function HeroSingleBlockHomePage({ segment, ...props }) {
  return (
    <>
      <div
        style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
        className={classNames(
          props.paddingClasses,
          "text-center sm:text-left",
          "relative bg-cover bg-left sm:bg-center",
          "h-fit sm:h-full min-h-[280px] sm:min-h-[75vh] 2xl:min-h-[60vh]"
        )}
      >
        <SegmentContainer>
          <div className="w-full sm:max-w-[61.8%] xl:max-w-[50%]">
            {segment.block.heading && (
              <div
                className={classNames(
                  "text-2xl sm:text-3xl md:text-4xl xl:text-4.5xl font-semibold",
                  "leading-snug sm:leading-snug xl:leading-tight whitespace-pre-line"
                )}
              >
                <PortableTextPlus value={segment.block.heading} />
              </div>
            )}

            {segment.block.content && (
              <div
                className={classNames(
                  "hidden sm:block mt-6 space-y-6 sm:leading-snug prose prose-headings:font-normal",
                  "prose-headings:sm:text-base prose-headings:md:text-lg prose-headings:2xl:text-xl"
                )}
              >
                <PortableTextPlus value={segment.block.content} />
              </div>
            )}
            {segment.block.callToActions && (
              <div className="mt-8 hidden pb-8 sm:flex flex-wrap gap-4 leading-snug justify-start">
                {segment.block.callToActions.map((callToAction) => (
                  <div className="flex-1 max-w-[256px]" key={callToAction._key}>
                    <CallToActionButton
                      className="w-full"
                      href={callToAction.url}
                      {...callToAction}
                    >
                      {callToAction.title}
                    </CallToActionButton>
                  </div>
                ))}
              </div>
            )}
          </div>
        </SegmentContainer>
      </div>

      {/* Mobile Only */}
      {(segment.block.content || segment.block.callToActions) && (
        <SegmentContainer
          className="sm:hidden h-full py-10 text-center"
          style={props.bgColorStyle}
        >
          {segment.block.content && (
            <div
              className={classNames(
                "leading-snug space-y-6 prose prose-headings:font-normal",
                "prose-headings:text-base"
              )}
            >
              <PortableTextPlus value={segment.block.content} />
            </div>
          )}
          {segment.block.callToActions &&
            segment.block.callToActions.map((callToAction) => (
              <div key={callToAction._key} className="mt-8">
                <CallToActionButton href={callToAction.url}>
                  {callToAction.title}
                </CallToActionButton>
              </div>
            ))}
        </SegmentContainer>
      )}
    </>
  );
}
